<template>
  <v-container fluid>
    <v-card flat>
      <v-form
        lazy-validation
        v-on:submit.prevent="onSubmit"
        enctype="multipart/form-data"
        ref="form"
      >
        <v-card-title>
          <span class="headline primary--text">{{ titulo }}</span>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined class="mr-2" ripple color="primary" icon="icon" @click="cancelar" v-bind="attrs" v-on="on">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>Voltar</span>    
          </v-tooltip>
        </v-card-title>
 
        <v-card-text>
          <v-card-title>Dados Pessoais</v-card-title>
          <v-row>
          <v-col md="12" cols="12">
                <v-autocomplete
                  :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
                  rounded
                  filled
                  v-model="agendamento.unidade"
                  :items="unidades"
                  label="Unidade"
                  item-text="nome"
                  item-value="nome"
                  return-object
                  chips
                  @change="changeUnidade"
                  :rules="rules.campoObrigatorio"
                >
                  <template v-slot:selection="{ item}">
                    <v-chip small>
                      <span>{{ item.nome }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12">
              <v-autocomplete
              :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
                rounded
                filled
                v-model="agendamento.tipoConsulta"
                :items="tiposConsultas"
                label="Tipo Consulta"
                item-text="nome"
                item-value="id"
                return-object
                chips
                :rules="rules.campoObrigatorio"
              >
                <template v-slot:selection="{ item}">
                  <v-chip small>
                    <span>{{ item.nome }}</span>
                  </v-chip>
                </template>
                <template v-slot:prepend >
                  <span class="custom-loader" v-if="loadingTipoConsultas">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            </v-row>

          <v-row>
            <!-- :loading="loadingBtnVisualizarImpressao" -->
            <v-col md="6" cols="12" >
              <v-text-field
              :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
                name="cpf"
                filled
                rounded
                :rules="rules.campoObrigatorio"
                v-model="agendamento.paciente.cpf"
                label="CPF"
                v-mask="'###.###.###-##'"
                placeholder="999.999.999-99"
                @blur="preencherPacienteFrm"
                :readonly="loadingCpf"
              >
                <template v-slot:prepend >
                  <span class="custom-loader" v-if="loadingCpf">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>
  
            <v-col md="3" cols=12 >
              <v-text-field
              :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
              name="rg"
              filled
              rounded
              v-model="agendamento.paciente.rg"
              label="RG"
              v-mask="['#.###.###','##.###.###','###.###.###']"
              placeholder="999.999.999"
              :readonly="loadingRg"
              >
                <template v-slot:prepend >
                  <span class="custom-loader" v-if="loadingRg">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            
            <v-col md="3" cols=12>
              <v-text-field
              :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
              name="localEmissaoRg"
              filled
              rounded
              v-model="agendamento.paciente.localEmissaoRg"
              label="Local de emissão"
              :readonly="loadingRg"
              >
                <template v-slot:prepend >
                  <span class="custom-loader" v-if="loadingRg">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>

          </v-row>
          <v-row>
            <v-col md="6" cols="12">
              <v-text-field
              :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
                name="nome"
                filled
                rounded
                :rules="rules.textoObrigatorio"
                v-model.trim="agendamento.paciente.nome"
                :readonly="loadingCpf"
                label="Nome"
              >
              <template v-slot:prepend >
                  <span class="custom-loader" v-if="loadingRg & loadingCpf">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            
            <v-col md="3" cols="12">
              <v-text-field
              :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
                name="email"
                filled
                rounded
                :rules="rules.emailObrigatorio"
                :readonly="loadingCpf"
                v-model="agendamento.paciente.email"
                label="Email"
              >
              <template v-slot:prepend >
                  <span class="custom-loader" v-if="loadingRg & loadingCpf">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-menu
                v-model="dataNascimentoMenu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
                    v-model="dataNascimento"
                    label="Data Nascimento"
                    prepend-icon="mdi-calendar"
                    @click:prepend="clickTeste"
                    placeholder="dd/mm/aaaa"
                    filled
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    v-mask="'##/##/####'"
                    @blur="agendamento.paciente.dataNascimento = parseDateNoTime(dataNascimento)"
                    :readonly="loadingCpf"
                    :rules="rules.campoObrigatorio"
                  >
                  <template v-slot:prepend >
                  <span class="custom-loader" v-if="loadingRg & loadingCpf">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="agendamento.paciente.dataNascimento"
                  @input="dataNascimentoMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

          </v-row>
          <v-row>
            <v-col md="6" cols="12">
                <v-text-field
                :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
                  name="telefone"
                  filled
                  rounded
                  :rules="rules.campoObrigatorio"
                  v-model="agendamento.paciente.telefone"
                  label="Telefone"
                  v-mask="['(##)#####-####','(##)####-####']"
                  placeholder="(99) 99999-9999"
                  :readonly="loadingCpf"
                >
                <template v-slot:prepend >
                  <span class="custom-loader" v-if="loadingRg & loadingCpf">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
                </v-text-field>
              </v-col>
            <v-col cols="12" sm="6">
              <v-select
                rounded
                filled
                v-model="agendamento.paciente.sexo"
                :items="genders"
                label="Sexo"
                :disabled="permiteEditarCamposPaciente"
                item-text="nome"
                item-value="id"
                return-object
              >
              <template v-slot:prepend >
                  <span class="custom-loader" v-if="loadingRg & loadingCpf">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-select>
            </v-col>
            
            
              

          </v-row>
        </v-card-text>
        <v-card-text>
          <v-card-title>Dados Agendamento</v-card-title>
          <v-row>

            <v-col cols="12" md="5">
              <v-text-field
              :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado"
                v-model="dataConsulta"
                filled
                rounded
                label="Data da Consulta"
                placeholder="dd/mm/aaaa hh:mm"
                persistent-hint
                @blur="agendamento.dataConsulta = parseDate(dataConsulta)"
                v-mask="'##/##/#### ##:##'"
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" md="4">
              <VueCtkDateTimePicker :no-value-to-custom-elem="true"
                id="DateTimePicker"
                overlay
                @validate="agendamento.dataConsulta = parseDatePicker(datePicker)"
                v-model="datePicker"
                format="YYYY-MM-DD HH:mm"
                label="Selecione data e hora"
                no-button-now
                :disabled="agendamentoStatusAtendido || agendamentoStatusConfirmado"
              >
                <v-btn outlined class="mr-2" ripple color="primary" icon="icon" :disabled="(inativo && !isAdminMaster()) || agendamentoStatusAtendido || agendamentoStatusConfirmado">
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </VueCtkDateTimePicker>
            </v-col>
            <v-col cols="12" md="4">
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-4">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="cancelar"> cancelar </v-btn>
          <v-btn type="submit" color="primary" v-show="(!inativo || !$route.params.id) || isAdminMaster()" :disabled="agendamentoStatusAtendido"> Gravar </v-btn>
        </v-card-actions>

        <alerta></alerta>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions, mapGetters } from "vuex";
import moment from 'moment'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { bus } from '../../main'
import { isAdminMaster } from "../../seguranca";

export default {
  name: "agendamento-create-edit",
  data() {
    return {
      inativo: true,
      agendamentoAtendido: false,
      loadingRg:false,
      loadingCpf:false,
      loadingTipoConsultas: false,
      isPacienteEncontrado:false,
      isJaConsultouPaciente:false,
      isEnableField: false,
      loadingBtnVisualizarImpressao: false,
      append: null,
      horarios: [],
      tiposConsultas: [],
      tiposAtendimentos: [],
      isDisabledCpf: true,
      isDisabledOutrosDadosPessoais: true,
      dataNascimento: "",
      dataNascimentoMenu: false,
      dataConsulta: "",
      datePicker: "",
      dataConsultaMenu: false,
      tab: null,
      statuss: [{nome: 'AGENDADO', id:'1'},{nome: 'PENDENTE', id:'2'},{nome: 'CONFIRMADO', id:'3'}, {nome: 'EM CANCELADO', id:'4'}, {nome: 'ATENDIDO', id:'5'}] ,
      tabItems: [
        "Dados Pessoais",
        "Dados Profissionais",
        "Perfil",
        "Empresa/Permissões",
      ],
      agendamento: {
        paciente: {
          cpf: '',
          dataNascimento: "",
        },  
        dataConsulta: "",      
      },
      genders: [
        { id: "M", nome: "Masculino" },
        { id: "F", nome: "Feminino" },
      ],
      modalidades: [],
      empresas: [],
      especialidades: [],
      ufs: [],
      permissoes: [],
      empresasSelecionadas: [],
      unidades: [],
      setores: [],
      grupoEmpresas: [],
      errors: [],
      titulo: "Novo Agendamento",
      confirm: {
        title: "Atenção",
        contentHtml:
          "Tem certeza que deseja apagar os usuário(s) selecionada(s).",
        ok: "Sim",
        cancel: "Não",
      },
      rules: {
        campoObrigatorio: [
          v => !!v || 'Campo é obrigatório',
        ],
        textoObrigatorio: [
          v => !!v || 'Campo é obrigatório',
          v => v && !!v.trim() || 'Valor não pode ser branco',
        ],
        emailObrigatorio: [
          v => !!v || 'E-mail obrigatório',
          v => /.+@.+\..+/.test(v) || 'E-mail inválido',
        ],    
        selecaoObrigatoria: [
          (v) => v.length > 0 || "Seleção Obrigatória",
        ],
      },
      dialog: false,
      snackbar: false,
      tipoAlerta: "",
      timeoutAlerta: 6000,
      textAlerta: "",
      perfilFile: null,
      urlPerfilFile: "",
      alteraPerfilFile: false,
      assinaturaFile: null,
      urlAssinaturaFile: "",
      alteraAssinaturaFile: false,
    };
  },
  components: { 
    Alerta,
    VueCtkDateTimePicker 
  },
  computed: {
    ...mapGetters(["ultimaTela"]),
    permiteEditarCamposPaciente(){

      if (this.agendamentoAtendido) return true;

      return !this.isEnableField;
    },
    agendamentoStatusAtendido(){
      return this.agendamentoAtendido;
    },
    agendamentoStatusConfirmado() {
      if(this.agendamento.statusAgendamento) {
        return this.agendamento.statusAgendamento.id == 3 ? true : false;
      }
      return false;
    }

  },
  watch: {
    isEnableField(){
   
    },
    "agendamento.paciente.dataNascimento"() {
      if (this.agendamento.paciente.dataNascimento){
        this.dataNascimento = moment(this.agendamento.paciente.dataNascimento, "YYYY-MM-DD")
          .format("DD/MM/YYYY");    
      }                                
    },
    "agendamento.dataConsulta"() {
      console.log(this.agendamento.dataConsulta);
      this.dataConsulta = this.formatDate(this.agendamento.dataConsulta);
      this.datePicker = this.parseDatePicker(this.agendamento.dataConsulta)
    },
    append(){
      const l = this.append
      this[l] = !this[l]

      //setTimeout(() => (this[l] = false), 3000)

      this.append = null
    },
  },
  methods: {
    ...mapActions(["setAgendamentoQuery", "setAlerta"]),
    clickTeste(aa){
      this.dataNascimentoMenu = !this.dataNascimentoMenu;
      console.log("AAAAAAAAAAAAAAAAAA")
      console.log(aa)
    },
    isAdminMaster(){
      return isAdminMaster();
    },
    loadModel() {
      var self = this;
      bus.$emit('clickSearchFilter', '');
      if (this.$route.params.id) {
        this.titulo = "Alterar Agendamento";
        this.$http
          .get(`/agendamento/${this.$route.params.id}`)
          .then((response) => {
            
            self.agendamento = response.data;

            if (self.agendamento.statusAgendamento){

              if (self.agendamento.statusAgendamento.id == 5 || self.agendamento.statusAgendamento.id == 4){ // atendido
                self.agendamentoAtendido = true;
              }

            }



            if(self.agendamento.unidade.id) {
              this.buscarTiposConsultas();
            }

            self.inativo = 
                self.agendamento.unidade.situacao === false || 
                self.agendamento.unidade.empresa.situacao === false
            
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao carregar Agendamento",
            });
          });
      }else{
        this.inativo = false;
      }
    },
    preencherPacienteFrm(val) {
      console.log("preencherPacienteFrm")
      this.loader = 'loadingBtnVisualizarImpressao'
      console.log(val.target.value)
      console.log(val.target.value.length)

      if (val.target.value.length == 14){
        this.loadingRg = true;
        this.loadingCpf = true;
        this.isJaConsultouPaciente = true;
        // let url = `/paciente?cpf=${val.target.value}`
        let url = `/paciente/byCpfAndUnidadeId/${val.target.value},${this.agendamento.unidade.id}`
        var self=this;
        console.log(url)

        this.$http
          .get(url)
          .then( response => {
            self.loadingRg = false;
            self.loadingCpf = false;

            // if (response.data.totalSize > 0){
            if (response.data){
              self.isPacienteEncontrado = true;
              self.isEnableField = false;
              // self.agendamento.paciente = Object.assign({}, response.data.content[0]);
              self.agendamento.paciente = Object.assign({}, response.data);
            }else{
              self.isEnableField = true;
              self.isPacienteEncontrado = false;

              self.dataNascimento = "";
              self.agendamento.paciente = Object.assign({}, {cpf: this.agendamento.paciente.cpf, dataNascimento: null});
            }
           })
        .catch((error) => {
          if(error.response && error.response.status == 404) {
            
            self.loadingRg = false;
            self.loadingCpf = false;
            self.isEnableField = true;
            self.isPacienteEncontrado = false;

            self.dataNascimento = "";
            self.agendamento.paciente = Object.assign({}, {cpf: this.agendamento.paciente.cpf, dataNascimento: null});

          } else {
            
            self.loadingRg = false;
            self.loadingCpf = false;
            self.isPacienteEncontrado = false;
            self.isEnableField = true;

            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao buscar Paciente",
            });
          }
        });
      }
     
      this.isDisabledOutrosDadosPessoais = false;
      
    },
    changeUnidade(val) {
      console.log("changeUnidade")
      console.log(val)

      this.isDisabledCpf = false;
     
      this.buscarTiposConsultas();
    },
    
    async onSubmit() {
      var self = this;
      
      if(!this.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos'
        })
      } else {      
        
        self.agendamento.statusAgendamento = Object.assign({}, {nome: 'PENDENTE', id:'2'}) ;
        
        if (self.agendamento.unidade != null){
          self.agendamento.unidade = Object.assign({}, self.agendamento.unidade);
        }

        if (self.agendamento.paciente.id != null){
          self.agendamento.paciente = Object.assign({}, self.agendamento.paciente);
        }else{
          self.agendamento.paciente.unidade = Object.assign({}, self.agendamento.unidade) ;
          self.agendamento.paciente.prontuario = ".";
        }

        if (self.agendamento.paciente.sexo != null){
          self.agendamento.paciente.sexo = self.agendamento.paciente.sexo.id
        }

        /* SUBSTITUIDO DEVIDO A UTILIZAÇÃO DO COMPONENTE DATEPICKER
        const { /*id,*-/ hora, data, /*dia*-/ } = this.agendamento.dataConsulta
        this.agendamento.dataConsulta = data.split("/")[2] + '-' + data.split("/")[1] + '-' + data.split("/")[0] + 'T' + hora + ':00' + '+0000' //moment(`${id}:00`, "dd/mm/yyyy HH:mm:ss").format(); 
        */

        if (this.agendamento.id) {

          this.$http
            .put("/agendamento", self.agendamento)
            .then(() => {
              self.setAgendamentoQuery({ query: self.agendamento.paciente.nome });
              self.cancelar();
              self.setAlerta({
                tipoAlerta: "success",
                textAlerta: "Agendamento atualizado com sucesso",
              });
            })
            .catch(() => {
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: "Erro ao atualizar o Agendamento",
              });
            });
        } else {
          
            self.$http
              .post("/agendamento", self.agendamento)
              .then(() => {
                self.setAgendamentoQuery({ query: self.agendamento.paciente.nome });
                self.cancelar();
                self.setAlerta({
                  tipoAlerta: "success",
                  textAlerta: "Agendamento criado com sucesso",
                });
              })
              .catch(() => {
                self.setAlerta({
                  tipoAlerta: "error",
                  textAlerta: "Erro ao criar Agendamento",
                });
              });
        }
      }  
    },
    
    buscarModalidades() {
      var self = this;
      this.$http
        .get(`/modalidade?max=50`)
        .then((response) => {
          this.modalidades = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar modalidades",
          });
        });
    },
    buscarEmpresas() {
      var self = this;
      this.$http
        .get(`/empresa?max=500`)
        .then((response) => {
          this.empresas = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar empresas",
          });
        });
    },
    buscarUnidades() {
      var self = this;
      console.log('buscando unidades')

      if (this.$route.params.id){ //edição
        this.$http
          .get(`/unidade?max=-1`)
          .then((response) => {
            console.log('retornado unidades')
            var arrUnidades = response.data.content;
            self.unidades = arrUnidades; 
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao buscar unidades",
            });
          });
      }else{ //inclusao

        this.$http
          .get(`/unidade?max=-1&situacao=true`)
          .then((response) => {
            console.log('retornado unidades')
            var arrUnidades = response.data.content;
            if (arrUnidades){
              arrUnidades = Object.assign([], arrUnidades.filter(u => u.empresa.situacao === true));
            }
            self.unidades = arrUnidades; 
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao buscar unidades",
            });
          });
      }
    },
    buscarTiposConsultas() {
      var self = this;
      this.tiposConsultas = [];
      let url = `/tipoConsulta?max=-1&situacao=true&unidadeId=`+this.agendamento.unidade.id;
      console.log('buscando tipos consultas')
      console.log(url)
      this.loadingTipoConsultas = true;
      this.$http
        .get(url)
        .then((response) => {
          this.loadingTipoConsultas = false;
          console.log('retornado tipos consultas')
          self.tiposConsultas = response.data.content;
        })
        .catch(() => {
          this.loadingTipoConsultas = false;
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao tipos consultas",
          });
        });
    },
    buscarTiposAtendimentos() {
      var self = this;
      console.log('buscando tipos atendimentos')
      this.$http
        .get(`/tipoAtendimento?max=-1`)
        .then((response) => {
          console.log('retornado tipos atendimentos')
          self.tiposAtendimentos = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao tipos atendimentos",
          });
        });
    },
    buscarGrupoEmpresas() {
      var self = this;
      this.$http
        .get(`/grupoEmpresa?max=500&situacao=true`)
        .then((response) => {
          this.grupoEmpresas = response.data.content;
        })
        .catch(() => {  
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Grupos de Empresas",
          });
        });
    },
    buscarSetores() {
      var self = this;
      this.$http
        .get(`/setor?max=500`)
        .then((response) => {
          this.setores = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar setores",
          });
        });
    },
    buscarPermissoes() {
      var self = this;
      this.$http
        .get(`/permissao?max=500`)
        .then((response) => {
          this.permissoes = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar permissão",
          });
        });
    },
    buscarUfs() {
      var self = this;
      this.$http
        .get(`/estado?max=500`)
        .then((response) => {
          this.ufs = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Ufs",
          });
        });
    },
    buscarEspecialidades() {
      var self = this;
      this.$http
        .get(`/especialidade?max=500`)
        .then((response) => {
          this.especialidades = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Especialidades",
          });
        });
    },
    buscarDatasAgendamentos(){
      let self = this;
      self.$http
        .get(`/agendamento/agenda/0`)
        .then((response) => {
          console.log(`/agendamento/agenda/0`)
          console.log(response)
          self.horarios = [];
          
          let nomeDiaSemana = "";
          
          for(let agenda of response.data){
            
            if (nomeDiaSemana != agenda.nomeDiaSemana){
                self.horarios.push (Object.assign({}, {
                  "header": `${agenda.nomeDiaSemana} - ${agenda.dia}/${agenda.mes}`
                }));
              }

              self.horarios.push (Object.assign({}, {
                  "id": `${agenda.dia}/${agenda.mes}/${agenda.ano} ${agenda.hora}:${agenda.minuto}`, 
                  "hora": `${agenda.hora}:${agenda.minuto}`, 
                  "data": `${agenda.dia}/${agenda.mes}/${agenda.ano}` , 
                  "dia": `${agenda.nomeDiaSemana}`, 
                  "disabled": agenda.agendado
              }));

              nomeDiaSemana = agenda.nomeDiaSemana;
              //console.log(nomeDiaSemana)
          }
          console.log(self.horarios)

        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Datas para agendamento",
          });
        });

    },
    formatDate (date) {
      if (!date) return null
      
      return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")
    },
    parseDate (date) {
      if (!date) return null
      
      return moment(date, "DD/MM/YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss")
    },    
    parseDateNoTime (date) {
      if (!date) return null
      
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")
    }, 
    parseDatePicker (date) {
      if (!date) return null

      return moment(date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DDTHH:mm:ss")
    },    
    onPerfilFileChange() {
      if(this.perfilFile) {
        this.alteraPerfilFile = true;

        var reader = new FileReader();
        reader.readAsDataURL(this.perfilFile);
        reader.onload = () => {
          this.urlPerfilFile = reader.result;
        };
      }
      else {
        this.alteraPerfilFile = false;
      }
    },
    onAssinaturaFileChange() {
      if(this.assinaturaFile) {
        this.alteraAssinaturaFile = true;

        var reader = new FileReader();
        reader.readAsDataURL(this.assinaturaFile);
        reader.onload = () => {
          this.urlAssinaturaFile = reader.result;
        };
      }
      else {
        this.alteraAssinaturaFile = false;
      }
    },
    getFileStorage(fileObj) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          storage: "hc-teste",
        },
      };
      
      const body = JSON.stringify({
        storageFileName: fileObj.nomeArquivo,
        timeUnit: "MINUTES",
        time: 10
      });

      return this.$storage.post(
        "/cadastro/upload/v1/createTemporaryLink",
        body,
        config,
      );
    },
    clickFile() {},
    async uploadPerfilFile() {

      const formData = new FormData();
      formData.append("file", this.perfilFile);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          codEmpresa: "01",
          codLocal: "001",
          descricao: "Arquivo de imagem do perfil do usuário",
        },
      };

      return this.$storage.post(
        "/cadastro/upload/v1/sync",
        formData,
        config
      );
    },
    async uploadAssinaturaFile() {

      const formData = new FormData();
      formData.append(
        "file",
        this.assinaturaFile
      );

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          codEmpresa: "01",
          codLocal: "001",
          descricao: "Arquivo de assinatura do usuário",
        },
      };

      return this.$storage.post(
        "/cadastro/upload/v1/sync",
        formData,
        config
      );
    },
    cancelar() {
      if (this.ultimaTela) {
        this.$router.push({ path: this.ultimaTela });
      } else {
        this.$router.go(-1);
      }
    },
  },
  created() {
    this.buscarDatasAgendamentos();
    this.buscarUnidades();
    
    //this.buscarTiposAtendimentos();
    this.loadModel();
    //this.buscarModalidades();
    //this.buscarEmpresas();
    
    //this.buscarGrupoEmpresas();
    //this.buscarSetores();
    //this.buscarPermissoes();
    //this.buscarUfs();
    //this.buscarEspecialidades();
  },
};
</script>
<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}



.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

